import React, { Component, createRef } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Logo from '../components/logo'
import Layout from '../layouts/index'

class HomeTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _mission: {
        height: 0,
        width: 0,
      },

      _vision: {
        height: 0,
        width: 0,
      },

      _solutions: {
        height: 0,
        width: 0,
      },
    }

    this._mission = createRef()
    this._vision = createRef()
    this._solutions = createRef()
  }

  componentDidMount() {
    this.updateSize()
    window.addEventListener('resize', this.updateSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize)
  }

  updateSize = () => {
    const _mission = {
      height: this._mission.current.clientHeight,
      width: this._mission.current.clientWidth,
    }

    const _vision = {
      height: this._vision.current.clientHeight,
      width: this._vision.current.clientWidth,
    }

    const _solutions = {
      height: this._solutions.current.clientHeight,
      width: this._solutions.current.clientWidth,
    }

    this.setState({ _mission, _vision, _solutions })
  }

  render() {
    const data = this.props.data
    const posts = data.allWordpressPost.edges
    const solutions = posts.find(post => post.node.slug === 'solutions').node
    const mission = posts.find(post => post.node.slug === 'mission').node
    const vision = posts.find(post => post.node.slug === 'vision').node
    const contact = posts.find(post => post.node.slug === 'contact-us').node
    const about = posts.find(post => post.node.slug === 'about-us').node
    const intro = posts.find(post => post.node.slug === 'intro').node

    return (
      <Layout>
        <Helmet>
          <title>Summit Vision</title>
          <meta
            name="description"
            content="Making quality and affordable healthcare the norm in sub-Saharan Africa"
          />
          <meta
            name="keywords"
            content="summit, vision, eye, eyes, summit vision, "
          />
        </Helmet>
        <div className="home">
          <div className="content">
            <div className="container">
              <nav className="nav">
                <div className="nav__logo">
                  <Logo />
                </div>
              </nav>

              <div className="space lg" />

              <div className="row">
                <div
                  className="col-md-10 col-lg-5"
                  style={{ position: 'static' }}
                >
                  <div className="section section--intro">
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: intro.title }}
                    />
                    <div
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: intro.content }}
                    />
                  </div>

                  <div className="section section--about">
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: about.title }}
                    />
                    <div
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: about.content }}
                    />
                  </div>

                  <div
                    className="section section--wt section--vision"
                    ref={this._vision}
                    style={{
                      right: this.state._mission.width,
                      top: 200 + this.state._mission.height / 2,
                    }}
                  >
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: vision.title }}
                    />
                    <div
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: vision.content }}
                    />
                  </div>

                  <div
                    className="section section--wt section--mission"
                    ref={this._mission}
                  >
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: mission.title }}
                    />
                    <div
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: mission.content }}
                    />
                  </div>

                  <div
                    className="section section--wt  section--solutions"
                    ref={this._solutions}
                    style={{ top: 170 + this.state._mission.height }}
                  >
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: solutions.title }}
                    />
                    <div
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: solutions.content }}
                    />
                  </div>

                  <div className="section section--contact">
                    <h3
                      className="section__title"
                      dangerouslySetInnerHTML={{ __html: contact.title }}
                    />
                    <div
                      className="section__content"
                      dangerouslySetInnerHTML={{ __html: contact.content }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default HomeTemplate

export const pageQuery = graphql`
  query postsQuery {
    allWordpressPost {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
  }
`
